import { Injectable } from '@angular/core';

import { ErrorService } from './error-service';
import { HelpService } from './help-service';
import { LoadingService } from './loading-service';
import { LocationService } from './location-service';
import { RequestService } from './request-service';
import { ToastService } from './toast-service';

const CONTACT_API_ROOT = 'contact/request-email/';

const SUCCESS_MSG = 'Success. You will receive an email with the requested documents';
const FAIL_MSG = 'We could not send that email. Please try again later';

/**
 * A service to send POST requests for specific docs to be emailed to the end user.
 */
@Injectable({ providedIn: 'root' })
class ContactService {
  constructor(
    private errorService: ErrorService,
    private helpService: HelpService,
    private loadingService: LoadingService,
    private locationService: LocationService,
    private requestService: RequestService,
    private toastService: ToastService
  ) {}

  public requestTaxYearInvoice(taxYear: number): Promise<any> {
    const url = `${CONTACT_API_ROOT}invoice_summary/`;
    return this.requestService.post(url, { taxYear: taxYear });
  }

  public requestAllTimeInvoice(): Promise<any> {
    const url = `${CONTACT_API_ROOT}invoice_summary/`;
    return this.requestService.post(url, { allTime: true });
  }

  public requestPaymentPeriodInvoice(periodId: string): Promise<any> {
    const url = `${CONTACT_API_ROOT}worker_invoice_api/`;
    return this.requestService.post(url, { paymentPeriod: periodId });
  }

  public requestSelfEmployedLetter(): Promise<any> {
    return this.requestService.post(`${CONTACT_API_ROOT}self_employed/`, {});
  }

  public requestWorkerContract(): Promise<any> {
    return this.requestService.post(`${CONTACT_API_ROOT}worker_contract/`, {});
  }

  /**
   * Trigger a request to call the customer.
   *
   * Retrieve a telephone number from the server, and attempt to trigger the
   * call, depending on the platform.
   */
  public async requestCustomerCall(visit, reason): Promise<void> {
    this.loadingService.showLoader({});

    // Get the location, ignoring errors
    const location = await this.locationService.getLocation().catch(() => null);

    try {
      const response = await this.helpService.requestCustomerCall(visit, reason, location);
      const { telephoneNumber } = response;
      this.loadingService.dismissLoader();
      document.location.href = `tel:${telephoneNumber}`;
    } catch (err) {
      this._handleRequestCustomerCallError(err);
    }
  }

  /**
   * Handle a failed request to call a customer.
   *
   * Failures are expected - the server applies time-based validation to decide
   * whether the user can call their customer.
   */
  private _handleRequestCustomerCallError(err: any): void {
    const isDemoModeError = this.errorService.isDemoModeError(err);

    this.loadingService.dismissLoader();

    if (isDemoModeError) {
      return;
    }

    const isNoInternetError = this.errorService.isNoInternetError(err);
    const isValidationError = this.errorService.isValidationError(err);
    const isExpectedError = isNoInternetError || isValidationError;
    let message;

    if (isNoInternetError) {
      message = 'You cannot request a call whilst offline';
    } else if (isValidationError) {
      message = this.errorService.getErrorMessage(err, 'You cannot call your customer right now.');
    } else {
      message = 'Something went wrong, please try again later.';
    }

    this.toastService.showToast({ message, theme: 'danger' });

    if (!isExpectedError) {
      throw err;
    }
  }
}

export { ContactService, FAIL_MSG, SUCCESS_MSG };
