import { model, Model } from '@housekeep/infra';

export enum SupportChannel {
  Callback = 'callback',
  Chat = 'chat',
  Email = 'email',
  HousekeeperCall = 'housekeeper_call',
  HousekeeperChat = 'housekeeper_chat'
}

export interface SelfServeButton {
  text: string;
  url: string;
}

export interface HelpCategoryDefinition extends Model {
  callbackEnabled: boolean;
  emailEnabled: boolean;
  liveChatEnabled: boolean;
  helpArticleText: string;
  recommendedChannel: SupportChannel;
  selfServeButtons: SelfServeButton[];
}

export const helpCategoryDefinition = model.extend({
  modelName: 'helpCategoryDefinition'
});

export const SUPPORT_CHANNEL_MAP: Record<SupportChannel, keyof Partial<HelpCategoryDefinition>> = {
  [SupportChannel.Callback]: 'callbackEnabled',
  [SupportChannel.Chat]: 'liveChatEnabled',
  [SupportChannel.Email]: 'emailEnabled',
  [SupportChannel.HousekeeperCall]: 'callbackEnabled',
  [SupportChannel.HousekeeperChat]: 'liveChatEnabled'
};
