import { WhatsNewSlide } from '../../whats-new-service';

export const LATEST_VERSION = '3.16.1';

const IMAGE_BASE_PATH = `/releases/${LATEST_VERSION}/whats-new/`;

/**
 * Return an image URL for the given filename, relative to the image base path.
 * @param filename
 */
function getImageUrl(filename): string {
  return `https://app-housekeepers.housekeep.com${IMAGE_BASE_PATH}${filename}`;
}

export const LATEST_SLIDES: WhatsNewSlide[] = [
  {
    format: 'fullBlue',
    title: '',
    description: '',
    imageUri: getImageUrl('1.png'),
    imageStyles: {
      'max-width': '300px'
    }
  },
  {
    format: 'split',
    title: 'Follow these top tips to ensure correct payment...',
    description: '',
    imageUri: getImageUrl('2.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'Start every job on time',
    description: `
      <p>
        This will let Housekeep and your customers know that you have started and it will give you access to <strong>better help options</strong>.
      </p>
    `,
    imageUri: getImageUrl('3.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'Need more time?',
    description: `
      <p>
        If you agree with your customer to stay longer at a job, <strong>add it to the job straight away</strong>.
      </p>
      <p>
        Tap 'Need more time?' during the clean to add the extra time.
      </p>
    `,
    imageUri: getImageUrl('4.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'No customer products?',
    description: `
      <p>
        If your customer has run out of products and you agree to use your own, you can <strong>update the job during the clean</strong>.
      </p>
      <p>
        Tap 'Help' then 'I need to use my own products' to add a charge for your products.
      </p>
    `,
    imageUri: getImageUrl('5.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'Finish every job',
    description: `
      <p>
        Tap <strong>'Finish'</strong> at the right time to notify your customers you've finished and to give them more time to rate and tip you.
      </p>
    `,
    imageUri: getImageUrl('6.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'Reply to all chats',
    description: `
      <p>
        Customers want to know that you've read their messages.
      </p>
      <p>
        Chat is also a great way to update your customers, ask questions and agree any changes to jobs.
      </p>
    `,
    imageUri: getImageUrl('7.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'We hope you like this update!',
    description: `
      <p>We've made some bug fixes and other improvements so that the app is easier to use.</p>
      <p>Let the team know if you have any app issues.</p>
    `,
    imageUri: getImageUrl('8.png'),
    imageStyles: {
      'max-width': '300px'
    }
  }
];
