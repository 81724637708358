import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';

import { Event, EventHint, Scope } from '@sentry/angular';
import * as Sentry from '@sentry/capacitor';

import { console } from './console';

/**
 * Status codes used by the `CacheService`
 * @see CacheService
 */
export enum CacheStatus {
  /** Cache status indicating the data in the cache is malformed. */
  Malformed = 'malformed',
  /** Cache status indicating an entry is not cached or that it expired. */
  Unset = 'unset'
}

/**
 * HTTP status codes recognised by the app.
 */
export enum HttpStatus {
  NoInternet = 0,
  DemoModeFeatureMissing = 1,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  TooManyRequests = 429,
  ServerError = 500,
  ServiceNotAvailable = 503
}

/**
 * Error codes used in Housekeep error responses.
 */
export enum ErrorCode {
  PermissionDenied = 'permission_denied',
  ValidationError = 'validation_error'
}

/**
 * An error that occurs when a 503 Service Unavailable HTTP response is received.
 * Errors of this type are grouped together when reported to Sentry
 * (it doesn't matter where in the app this error occurs - it is a backend service issue).
 */
class Error503 extends Error {
  constructor() {
    super('A `503` error response was received.');

    // See http://bit.ly/2jFnR9i
    Object.setPrototypeOf(this, Error503.prototype);
  }
}

/**
 * An error that occurs when a 401 Unauthorized HTTP response is received.
 * Errors of this type are not reported to Sentry.
 */
export class Unauthorized extends Error {
  constructor(message: string) {
    super(message);

    // See http://bit.ly/2jFnR9i
    Object.setPrototypeOf(this, Unauthorized.prototype);
  }
}

export class VisitNotInDay extends Error {
  constructor(message: string = '') {
    super(message);

    // See http://bit.ly/2jFnR9i
    Object.setPrototypeOf(this, VisitNotInDay.prototype);
  }
}

/**
 * Provides a hook for centralized exception handling.
 * @see AppModule
 * @see ErrorHandler
 */
export class HkErrorHandler extends ErrorHandler {
  /** A hook that is executed to capture unhandled errors and send data to Sentry. */
  public handleError(err: any): void {
    const rejectedError: Error = err && err.rejection;

    // Don't log Unauthorized errors
    if (rejectedError instanceof Unauthorized) {
      return;
    }

    let sentryErr = err;
    const extras: { [key: string]: any } = { originalError: false };

    // Wrap 503 response errors so that they can be grouped in Sentry
    if (rejectedError instanceof HttpErrorResponse && rejectedError.status === HttpStatus.ServiceNotAvailable) {
      sentryErr = new Error503();
      extras.location = rejectedError.url;
    }

    // Get the original error, if any
    if (sentryErr && sentryErr.originalError) {
      sentryErr = sentryErr.originalError;
      extras.originalError = true;
    }

    try {
      Sentry.withScope((scope: Scope) => {
        scope.setExtras(extras);
        Sentry.captureException(sentryErr);
      });
    } catch (e) {
      /* istanbul ignore next */
      console.error(e);
    }

    return super.handleError(sentryErr);
  }
}

/**
 * Hook executed before data is sent to Sentry.
 * This callback is registered when Sentry is initialised using `Sentry.init`.
 * @param event A Sentry exception event
 * @param hint A Sentry exception event hint
 * @see AppModule
 */
export function sentryBeforeSend(event: Event, hint?: EventHint): Event | null {
  // Only send the pathname as the URL
  try {
    event.request.url = new URL(event.request.url).pathname;
  } catch (err) {
    // do nothing
  }

  return event;
}
