import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { RemoteKeyTransfer, Visit } from '@housekeep/infra';

import { ChatInfoService } from 'services/chat-info-service';
import { ErrorService } from 'services/error-service';
import { HelpService } from 'services/help-service';
import { LoadingService } from 'services/loading-service';
import { LocationService } from 'services/location-service';
import { ToastService } from 'services/toast-service';

@Component({
  selector: 'contact-worker',
  templateUrl: './contact-worker.component.html',
  styleUrls: ['./contact-worker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactWorker implements OnInit {
  @Input() visit: Visit;
  @Input() remoteKeyTransfer: RemoteKeyTransfer;
  @Input() helpCategory: string;

  @Output() chatting = new EventEmitter<string>();

  public allowed: boolean;
  public message: string;

  constructor(
    private chatInfoService: ChatInfoService,
    private cdRef: ChangeDetectorRef,
    public errorService: ErrorService,
    public helpService: HelpService,
    public loadingService: LoadingService,
    public locationService: LocationService,
    public toastService: ToastService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.allowed = await this.chatInfoService.workerChatIsAllowed(this.remoteKeyTransfer);
    } catch (err) {
      this.allowed = false;
    }
    this.message = `Hello ${this.remoteKeyTransfer.otherWorker.firstNames}, I need help with our key transfer`;
    this.cdRef.markForCheck();
  }

  /**
   * Open the chat page with the customer and send a pre-defined message from the parent page
   */
  public goToChatWithMessage(): void {
    this.goToChat(this.message);
  }

  /**
   * Open the chat page with the customer without sending a pre-defined message from the parent page
   */
  public goToChatWithoutMessage(): void {
    this.goToChat('');
  }

  protected goToChat(message: string): void {
    this.chatting.emit(message);
  }

  /**
   * Trigger a request to call the worker.
   *
   * Retrieve a telephone number from the server, and attempt to trigger the
   * call, depending on the platform.
   */
  public async requestWorkerCall(): Promise<void> {
    const reason = this.helpCategory;
    const remoteKeyTransfer = this.remoteKeyTransfer;
    const visit = this.visit;

    this.loadingService.showLoader({});

    // Get the location, ignoring errors
    const location = await this.locationService.getLocation().catch(() => null);

    try {
      const response = await this.helpService.requestWorkerCall(visit, remoteKeyTransfer.id, reason, location);
      const { telephoneNumber } = response;
      this.loadingService.dismissLoader();
      document.location.href = `tel:${telephoneNumber}`;
    } catch (err) {
      this._handleRequestWorkerCallError(err);
    }
  }

  /**
   * Handle a failed request to call a worker.
   *
   * Failures are expected - the server applies time-based validation to decide
   * whether the user can call their worker.
   */
  private _handleRequestWorkerCallError(err: any): void {
    const isDemoModeError = this.errorService.isDemoModeError(err);

    this.loadingService.dismissLoader();

    if (isDemoModeError) {
      return;
    }

    const isNoInternetError = this.errorService.isNoInternetError(err);
    const isValidationError = this.errorService.isValidationError(err);
    const isExpectedError = isNoInternetError || isValidationError;
    let message;

    if (isNoInternetError) {
      message = 'You cannot request a call whilst offline';
    } else if (isValidationError) {
      message = this.errorService.getErrorMessage(err, 'You cannot call that Housekeeper right now.');
    } else {
      message = 'Something went wrong, please try again later.';
    }

    this.toastService.showToast({ message, theme: 'danger' });

    if (!isExpectedError) {
      throw err;
    }
  }
}
