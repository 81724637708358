<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button menu="menu-primary"></ion-menu-button>
    </ion-buttons>
    <ion-title>Ratings &amp; Pay</ion-title>
  </ion-toolbar>

  <ion-toolbar class="segments-toolbar">
    <ion-segment [(ngModel)]="segment">
      <ion-segment-button value="recent" (ionSelect)="selectedRecent()">
        Recent
      </ion-segment-button>

      <ion-segment-button value="all" (ionSelect)="selectedAll()">
        All time
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content class="performance ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-spinner
    *ngIf="!loadError && !pageReady"
    class="content__loading-spinner"
  ></ion-spinner>

  <hk-connection-failure
    *ngIf="loadError && !pageReady"
    (onRefresh)="doRefresh()"
  ></hk-connection-failure>

  <ng-container *ngIf="isRecentTab()">
    <ion-card *ngIf="isRecentlyConfirmed()" class="ion-text-center ion-margin-vertical">
      <ion-card-content>
        <p class="strong">Congratulations! You have passed your trial &nbsp;🎉</p>
      </ion-card-content>
    </ion-card>

    <h5
      *ngIf="worker?.isTrialling()"
      class="card-group-header performance__card-group-header mar-t-0"
    >
      Performance Goals
    </h5>

    <ion-card *ngIf="worker?.isTrialling()" class="ion-margin-vertical">
      <ion-card-header>Welcome to your Housekeep trial!</ion-card-header>
      <ion-card-content>
        <p [ngClass]="{ 'text--green strong': hasCompletedEnoughJobs() }">
          <hk-wc-icon
            [name]="hasCompletedEnoughJobs() ? 'tick-square' : 'square'"
            size="20"
          ></hk-wc-icon>
          <span>
            Complete {{ sinceActivationMetrics?.triallingTargetVisitCount }} jobs
          </span>
        </p>
        <p [ngClass]="{ 'text--green strong': hasSufficientRatingForTrial() }">
          <hk-wc-icon
            [name]="hasSufficientRatingForTrial() ? 'tick-square' : 'square'"
            size="20"
          ></hk-wc-icon>
          <span>
            Keep ratings above {{ metrics?.triallingTargetAverageRating }}
            <hk-wc-icon name="star" size="20"></hk-wc-icon>
          </span>
        </p>
        <p [ngClass]="{ 'text--green strong': hasSufficientReliabilityForBonus() }">
          <hk-wc-icon
            [name]="hasSufficientReliabilityForBonus() ? 'tick-square' : 'square'"
            size="20"
          ></hk-wc-icon>
          <span>0 late cancellations in {{ TARGET_DAYS_RELIABLE }} days</span>
        </p>
        <p
          [ngClass]="{ 'text--green strong': hasCompletedBackgroundCheck() }"
          *ngIf="hasBackgroundCheckStatus()"
        >
          <hk-wc-icon
            [name]="hasCompletedBackgroundCheck() ? 'tick-square' : 'square'"
            size="20"
          ></hk-wc-icon>
          <span>Complete background check</span>
        </p>
      </ion-card-content>
    </ion-card>

    <h5
      *ngIf="worker?.isTrialling()"
      class="card-group-header performance__card-group-header"
    >
      Jobs
    </h5>

    <ion-card *ngIf="worker?.isTrialling()" class="performance__card ion-margin-vertical">
      <ion-card-content>
        <ion-grid class="grid--negative-margin">
          <ion-row class="ion-align-items-center">
            <ion-col size="4">
              <div class="performance__progress">
                <hk-round-progress
                  [current]="
                    sinceActivationMetrics ? sinceActivationMetrics.visitCount : 0
                  "
                  [max]="
                    sinceActivationMetrics
                      ? sinceActivationMetrics.triallingTargetVisitCount
                      : 0
                  "
                  [color]="COLOR_GOOD"
                  [stroke]="25"
                  [responsive]="true"
                  [metricValue]="sinceActivationMetrics?.visitCount | asNumber"
                  [upperTargetLabel]="
                    sinceActivationMetrics
                      ? sinceActivationMetrics.triallingTargetVisitCount + ' jobs'
                      : ''
                  "
                >
                  <!-- prettier-ignore -->
                  <span metric-label>
                    Job<span *ngIf="sinceActivationMetrics && sinceActivationMetrics.visitCount !== 1">s</span>
                  </span>
                </hk-round-progress>
              </div>
            </ion-col>
            <ion-col class="mar-y-0 mar-l-10">
              <div *ngIf="hasCompletedEnoughJobs()">
                <ion-card-title class="ion-no-padding">Jobs</ion-card-title>
                <h2 class="text--green">
                  <strong>Goal achieved!</strong>
                </h2>
                <p>Keep going</p>
              </div>

              <div *ngIf="!hasCompletedEnoughJobs() && sinceActivationMetrics">
                <ion-card-title
                  class="ion-no-padding"
                  *ngIf="sinceActivationMetrics.visitCount === 0"
                >
                  No jobs completed yet
                </ion-card-title>
                <!-- prettier-ignore -->
                <ion-card-title
                  class="ion-no-padding"
                  *ngIf="sinceActivationMetrics.visitCount > 0 && !hasCompletedEnoughJobs()"
                >
                  <strong>
                    {{ sinceActivationMetrics.triallingTargetVisitCount - sinceActivationMetrics.visitCount }}
                    job{{ sinceActivationMetrics.triallingTargetVisitCount - sinceActivationMetrics.visitCount | plural
                    }} to go!
                  </strong>
                </ion-card-title>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

    <h5 class="card-group-header performance__card-group-header">Ratings</h5>

    <ion-card class="performance__card ion-margin-vertical">
      <ion-card-content>
        <ion-grid class="grid--negative-margin">
          <ion-row class="ion-nowrap ion-align-items-center">
            <ion-col size="4">
              <div class="performance__progress">
                <hk-round-progress
                  [current]="pageReady ? getAdjustedRating(metrics.ratingAverage) : 0"
                  [max]="getAdjustedRating(5)"
                  [color]="hasBadRating() ? COLOR_BAD : COLOR_GOOD"
                  [stroke]="25"
                  [responsive]="true"
                  metricLabel="Rating"
                  [lowerTarget]="
                    getAdjustedRating(
                      worker?.isTrialling()
                        ? metrics?.triallingTargetAverageRating
                        : metrics?.targetAverageRating
                    )
                  "
                >
                  <span metric-value>
                    <!-- prettier-ignore -->
                    <span *ngIf="pageReady && metrics.ratingCount">
                      {{ metrics.ratingAverage | asAverageRating }}<sup>
                        <hk-wc-icon name="star" size="20"></hk-wc-icon>
                      </sup>
                    </span>
                    <span *ngIf="!pageReady || !metrics.ratingCount" class="text--muted">
                      <hk-wc-icon name="star" size="24"></hk-wc-icon>
                    </span>
                  </span>
                  <span lower-target-label>
                    <span *ngIf="worker?.isTrialling()">
                      {{ metrics?.triallingTargetAverageRating }}
                    </span>
                    <span *ngIf="!worker?.isTrialling()">
                      {{ metrics?.targetAverageRating }}
                    </span>
                  </span>
                  <span upper-target-label>5.0</span>
                </hk-round-progress>
              </div>
            </ion-col>

            <ion-col class="mar-y-0 mar-l-10">
              <div *ngIf="pageReady">
                <ion-card-title class="ion-no-padding" *ngIf="isUnrated()">
                  No ratings yet
                </ion-card-title>
                <!-- prettier-ignore -->
                <div *ngIf="!isUnrated()">
                  <ion-card-title class="no-padding">
                    <strong *ngIf="hasPerfectRating()" class="text--green">
                      Perfect!
                    </strong>
                    <strong *ngIf="hasGreatRating()" class="text--green">
                      Great work!
                    </strong>
                    <strong *ngIf="hasGoodRating()">
                      Good work!
                    </strong>
                    <strong *ngIf="hasBadRating()">
                      You need more 5<hk-wc-icon name="star" size="24"></hk-wc-icon> ratings to access new customers!
                    </strong>
                  </ion-card-title>
                  <p>
                    {{ metrics.ratingCount | asNumber }} rating{{ metrics.ratingCount | plural }}
                  </p>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

    <h5 class="card-group-header performance__card-group-header">Reliability</h5>

    <ion-card
      class="performance__card ion-margin-vertical"
      tappable
      (click)="openReliabilityPage()"
    >
      <ion-card-content>
        <ion-grid class="grid--negative-margin">
          <ion-row class="ion-align-items-center">
            <ion-col size="4">
              <div class="performance__progress">
                <hk-reliability-round-progress
                  [reliability]="pageReady ? +metrics.reliability : 0"
                  [daysReliable]="pageReady ? metrics.daysReliable : 0"
                ></hk-reliability-round-progress>
              </div>
            </ion-col>

            <ion-col class="mar-y-0 mar-l-10">
              <div *ngIf="pageReady">
                <!-- prettier-ignore -->
                <p *ngIf="metrics.daysReliable === 0">
                  <strong [class.text--danger]="metrics.visitsAffectedLate > 0">
                    {{ metrics.visitsAffectedLate }} late cancellation{{ metrics.visitsAffectedLate | plural }}
                  </strong>
                </p>
                <div *ngIf="metrics.daysReliable > 0">
                  <ion-card-title class="ion-no-padding">
                    <strong
                      class="text--green"
                      *ngIf="hasSufficientReliabilityForBonus()"
                    >
                      Perfect!
                    </strong>
                    <strong *ngIf="!hasSufficientReliabilityForBonus()">
                      Keep building up your reliability streak
                    </strong>
                  </ion-card-title>
                  <p>
                    <!-- prettier-ignore -->
                    <span [class.text--danger]="metrics.visitsAffectedLate > 0">
                      {{ metrics.visitsAffectedLate }} late cancellation{{ metrics.visitsAffectedLate | plural }}
                    </span>
                  </p>
                </div>
              </div>
            </ion-col>

            <ion-col size="auto" class="performance__card__tap-icon">
              <ion-icon name="chevron-forward"></ion-icon>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

    <h5 class="card-group-header performance__card-group-header">Fulfilment</h5>

    <hk-fulfilment-card
      [metrics]="metrics"
      [tappable]="true"
      (tap)="openFulfilmentPage()"
    ></hk-fulfilment-card>

    <h5 class="card-group-header performance__card-group-header">
      <span *ngIf="!worker || worker.hourlyBonus">Base Rate &amp; Bonus Pay</span>
      <span *ngIf="worker && !worker.hourlyBonus">Pay</span>
    </h5>

    <ion-card class="performance__card ion-margin-vertical">
      <ion-card-content class="pad-15">
        <ion-grid class="grid--negative-margin">
          <ion-row class="ion-align-items-center">
            <ion-col size="4" class="ion-text-center">
              <div class="performance__gauge">
                <div
                  *ngIf="!worker || worker.hourlyBonus > 0"
                  class="performance__gauge__container"
                  (click)="animatePayNeedle()"
                >
                  <round-progress
                    [current]="1"
                    [max]="2"
                    [color]="COLOR_GOOD"
                    [background]="COLOR_SUCCESS"
                    [stroke]="90"
                    [semicircle]="true"
                    [responsive]="true"
                    [duration]="1"
                  ></round-progress>
                  <div
                    class="performance__gauge__container__label performance__gauge__container__label-left"
                  >
                    {{ worker?.basePay | asGbp : true }}
                  </div>
                  <!-- prettier-ignore -->
                  <div class="performance__gauge__container__label performance__gauge__container__label-right">
                    {{(worker ? worker.basePay + worker.hourlyBonus : null) | asGbp:true}}
                  </div>
                  <div
                    class="performance__gauge__container__needle"
                    [ngStyle]="getPayNeedleStyle()"
                  ></div>
                </div>
              </div>

              <ion-badge
                *ngIf="worker"
                class="performance__hourly-rate"
                [class.performance__hourly-rate--bonus]="worker?.isEligibleForBonus"
              >
                {{ worker?.getHourlyRate() | asGbp : true }}/hour
              </ion-badge>
            </ion-col>

            <ion-col class="mar-y-0 mar-l-10">
              <div *ngIf="!worker?.isTrialling()">
                <ion-card-title class="ion-no-padding">
                  <strong>
                    <span *ngIf="!worker?.isEligibleForBonus">Performance goals:</span>
                    <span class="text--green" *ngIf="worker?.isEligibleForBonus">
                      <hk-wc-icon
                        name="rosette"
                        size="24"
                        class="performance__bonus-icon"
                      ></hk-wc-icon>
                      You're on bonus pay!
                    </span>
                  </strong>
                </ion-card-title>
              </div>

              <ng-container *ngIf="!worker?.isTrialling()">
                <p [ngClass]="{ 'text--green strong': hasSufficientRatingForBonus() }">
                  <hk-wc-icon
                    [name]="hasSufficientRatingForBonus() ? 'tick-square' : 'square'"
                    size="20"
                  ></hk-wc-icon>
                  Keep ratings above {{ metrics?.targetAverageRating }}
                  <hk-wc-icon name="star" size="20"></hk-wc-icon>
                </p>
                <p
                  [ngClass]="{ 'text--green strong': hasSufficientReliabilityForBonus() }"
                >
                  <hk-wc-icon
                    [name]="hasSufficientReliabilityForBonus() ? 'tick-square' : 'square'"
                    size="20"
                  ></hk-wc-icon>
                  0 late cancellations in {{ TARGET_DAYS_RELIABLE }} days
                </p>
              </ng-container>
              <ng-container *ngIf="worker?.isTrialling()">
                <p *ngIf="worker?.hourlyBonus > 0">Pass trial to access bonus</p>
              </ng-container>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="hasUpcoming1YearPayIncrease()">
            <ion-col class="text--muted ion-text-center">
              1 year pay increase: {{ getPayIncreaseDate() | asDayWithYear }}
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ng-container>

  <ng-container *ngIf="isAllTimeTab()">
    <ion-card class="performance__table__card ion-margin-vertical" no-border>
      <ion-grid class="performance__table">
        <ion-row
          *ngIf="worker.lastActivationDt || worker.startedWork"
          class="performance__table__row"
        >
          <ion-col size="6" class="performance__table__col-1">
            <p *ngIf="worker.lastActivationDt">Start date</p>
            <p *ngIf="!worker.lastActivationDt">Estimated start date</p>
          </ion-col>
          <!-- prettier-ignore -->
          <ion-col size="6" class="performance__table__col-2">
            <p *ngIf="worker.lastActivationDt">{{ worker.lastActivationDt | asDayWithYear }}</p>
            <p *ngIf="!worker.lastActivationDt">{{ worker.startedWork | asDayWithYear }}</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6" class="performance__table__col-1"><p>Jobs</p></ion-col>
          <ion-col size="6" class="performance__table__col-2">
            <p>{{ metrics?.visitCount | asNumber }}</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>

    <h5 class="card-group-header performance__card-group-header">Ratings</h5>

    <ion-card
      class="performance__table__card ion-margin-vertical"
      [class.performance__table__card__border-blue]="!hasBadRating()"
      [class.performance__table__card__border-red]="hasBadRating()"
    >
      <ion-grid class="performance__table">
        <ion-row class="performance__table__row">
          <ion-col size="6" class="performance__table__col-1">
            <p><strong>Average Rating</strong></p>
          </ion-col>
          <ion-col size="6" class="performance__table__col-2">
            <strong>
              <p *ngIf="metrics && metrics.ratingCount === 0">No ratings yet</p>
              <p *ngIf="!metrics || metrics.ratingCount > 0">
                <span *ngIf="metrics?.ratingCount > 0">{{ metrics.ratingAverage }}</span>
                <hk-wc-icon name="star" size="18"></hk-wc-icon>
              </p>
            </strong>
          </ion-col>
        </ion-row>
        <ion-row class="performance__table__row">
          <ion-col size="6" class="performance__table__col-1">
            <p>Ratings</p>
          </ion-col>
          <ion-col size="6" class="performance__table__col-2">
            <p>{{ metrics?.ratingCount | asNumber }}</p>
          </ion-col>
        </ion-row>
        <ion-row class="performance__table__row">
          <ion-col size="6" class="performance__table__col-1">
            <p>
              5
              <hk-wc-icon name="star" size="16"></hk-wc-icon>
              ratings
            </p>
          </ion-col>
          <ion-col size="6" class="performance__table__col-2">
            <p>{{ metrics?.ratingFiveStarCount | asNumber }}</p>
          </ion-col>
        </ion-row>
        <ion-row class="performance__table__row">
          <ion-col size="6" class="performance__table__col-1">
            <p>
              4
              <hk-wc-icon name="star" size="16"></hk-wc-icon>
              ratings
            </p>
          </ion-col>
          <ion-col size="6" class="performance__table__col-2">
            <p>{{ metrics?.ratingFourStarCount | asNumber }}</p>
          </ion-col>
        </ion-row>
        <ion-row class="performance__table__row">
          <ion-col size="6" class="performance__table__col-1">
            <p>Low ratings</p>
          </ion-col>
          <ion-col size="6" class="performance__table__col-2">
            <p>{{ metrics?.ratingLowCount | asNumber }}</p>
          </ion-col>
        </ion-row>
        <ion-row class="performance__table__row">
          <ion-col size="6" class="performance__table__col-1">
            <p>Tips</p>
          </ion-col>
          <ion-col size="6" class="performance__table__col-2">
            <p>{{ metrics?.tipTotalPounds | asGbp }}</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>

    <h5 class="card-group-header performance__card-group-header">Reliability</h5>

    <ion-card
      class="performance__table__card performance__table__card__border-red ion-margin-vertical"
    >
      <ion-grid class="performance__table">
        <ion-row class="performance__table__row">
          <ion-col class="performance__table__col-1">
            <p><strong>Late cancellations</strong></p>
          </ion-col>
          <ion-col size="auto" class="performance__table__col-2">
            <p>
              <strong>{{ metrics?.visitsAffectedLate }}</strong>
            </p>
          </ion-col>
        </ion-row>

        <ion-row class="performance__table__row">
          <ion-col class="performance__table__col-1">
            <p><strong>Short-notice cancellations</strong></p>
          </ion-col>
          <ion-col size="auto" class="performance__table__col-2">
            <p>
              <strong>{{ metrics?.visitsAffectedShort }}</strong>
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </ng-container>

  <h5 class="card-group-header performance__card-group-header">Customer reviews</h5>

  <ng-container *ngIf="currentPeriod.weeks">
    <ng-container *ngFor="let week of currentPeriod.weeks">
      <ion-card class="ion-margin-vertical" *ngFor="let visitRating of week.visitRatings">
        <ion-card-header>
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-align-items-center">
              <ion-col
                size="auto"
                class="performance__ratings__rating"
                [class.performance__ratings__rating--high]="visitRating.isHigh"
                [class.performance__ratings__rating--okay]="visitRating.isOkay"
                [class.performance__ratings__rating--low]="visitRating.isLow"
              >
                <hk-wc-icon
                  *ngFor="let star of 5 | range"
                  [name]="star + 1 > visitRating.rating ? 'star' : 'star-fill'"
                  size="20"
                ></hk-wc-icon>
              </ion-col>
              <ion-col size="auto" *ngIf="visitRating.tipPounds">
                <tip-badge [amount]="visitRating.tipPounds"></tip-badge>
              </ion-col>
              <ion-col class="text--muted ion-text-right text-medium">
                {{ visitRating.visit.actualDate | asDay }}
              </ion-col>
            </ion-row>
            <ion-row *ngIf="visitRating.compliment">
              <ion-col>
                <hk-compliment
                  class="performance__ratings__compliment"
                  [compliment]="visitRating.compliment"
                  [complimentDisplay]="visitRating.complimentDisplay"
                ></hk-compliment>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-header>

        <ion-card-content
          *ngIf="visitRating.moreInfo || visitRating.customer"
          class="performance__ratings__card-content"
        >
          <blockquote class="performance__ratings__quote">
            <strong *ngIf="visitRating.problemCategoryDisplay">
              Problem: {{ visitRating.problemCategoryDisplay }}
            </strong>
            <ng-container *ngIf="visitRating.isHigh || visitRating.isOkay">
              <span *ngIf="visitRating.moreInfo" class="performance__ratings__quote-text">
                “{{ visitRating.moreInfo | capitaliseSentence }}”
              </span>
              <span *ngIf="visitRating.customer" class="performance__ratings__customer">
                <span *ngIf="visitRating.moreInfo">&ndash;&nbsp;</span>
                {{ visitRating.customer.preferredName | titlecase }}
              </span>
            </ng-container>
            <span
              *ngIf="isRecentTab()"
              class="text--muted ion-float-right mar-t-15"
              tappable
              (click)="openRatingDetailPage(visitRating)"
            >
              Details
              <ion-icon name="arrow-forward"></ion-icon>
            </span>
          </blockquote>
        </ion-card-content>
      </ion-card>
    </ng-container>
  </ng-container>

  <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
