import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { SetCurrentScreenOptions } from '@capacitor-firebase/analytics/dist/esm/definitions';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';

import * as moment from 'moment';

import { DateTime, durationBetween, toDate, underscoreDelimit, Worker } from '@housekeep/infra';

import { Serialized } from 'types/case-conversion';

import { AuthEventArgs, AuthService } from './auth-service';
import { HkSentryBreadcrumbCategory, SentryService } from './sentry-service';
import { TimeService } from './time-service';
import { UserService } from './user-service';

/**
 * When defining new analytics events and event parameters,
 * be aware of the following Firebase restrictions:
 *  - up to 500 distinct events
 *  - up to 25 event parameters per event
 *  - event names can be up to 40 characters
 *  - event parameter names can be up to 40 characters
 *  - event parameter values can be up to 100 characters
 *
 * When defining new user properties, be aware:
 *  - up to 25 user properties
 *  - user property names can be up to 24 characters
 *  - user property values can be up to 36 characters
 */

const FIREBASE_MAX_PARAM_VALUE_LENGTH = 100;

export enum AnalyticsEvent {
  /** An event indicating the user viewed an alert. */
  AlertView = 'alert_view',
  /** An event indicating the auth service has been initialised. */
  AuthReady = 'auth_ready',
  /** An event indicating the user has called their customer. */
  CallCustomer = 'call_customer',
  /** An event indicating the user has seen the customer call prompt in the chat client. */
  CallCustomerOffered = 'call_customer_offered',
  /** An event indicating an error occurred when the user tried to call their customer. */
  ErrorCallCustomer = 'error_call_customer',
  /** An event indicating an error occurred when the user tried to book time off. */
  ErrorTimeOff = 'error_time_off',
  /** An event indicating the user viewed a web page in an `InAppBrowser`. */
  ExternalPageView = 'external_page_view',
  /** An event indicating the user logged in. This is a pre-defined Firebase event name. */
  Login = 'auth_login',
  /** An event indicating the user logged out. */
  Logout = 'auth_logout',
  /** An event indicating the user viewed a menu. */
  MenuView = 'menu_view',
  /** An event indicating the user opened a push notification. */
  PushOpen = 'push_open',
  /** An event indicating the user viewed a tab. */
  TabView = 'tab_view',
  /** An event indicating the user clicked/tapped on something. */
  Tap = 'tap',
  /** An event indicating the user viewed a toast. */
  ToastView = 'toast_view'
}

/**
 * Permitted analytics event parameter names.
 */
export enum AnalyticsParam {
  /** An event parameter containing the name of an alert. */
  AlertName = 'alert_name',
  /** An event parameter containing noteworthy data related to an event. */
  Data = 'data',
  /** An event parameter containing the name of an element. */
  ElementName = 'element_name',
  /** An event parameter containing data related to an error. */
  Error = 'error',
  /** An event parameter containing the name of a menu. */
  MenuName = 'menu_name',
  /** An event parameter containing a message shown to the user. */
  Message = 'message',
  /** An event parameter containing a help reason. */
  Reason = 'reason',
  /** An event parameter containing the ID of something that triggered the event. */
  SourceId = 'source_id',
  /** An event parameter containing the timestamp of something that triggered the event. */
  SourceTimestamp = 'source_timestamp',
  /** An event parameter containing the name of a tab. */
  TabName = 'tab_name'
}

/**
 * An object containing analytics event parameter names and their values.
 */
export type AnalyticsParams = {
  [param in AnalyticsParam]?: string | number | boolean;
};

export const MENU_NAME_MAIN_MENU = 'Main Menu';
export const MENU_NAME_SCHEDULE = 'Schedule';

const USER_PROPERTY_DAYS_SINCE_ACTIVATION = 'days_since_activation';
const USER_PROPERTY_DAYS_SINCE_CONFIRMATION = 'days_since_confirmation';
const USER_PROPERTY_FCM_ACTIVE = 'fcm_active';
export const USER_PROPERTY_LOCATION_ACTIVE = 'location_active';

const WORKER_PROPERTIES_TO_TRACK: (keyof Worker)[] = [
  'id',
  'isEligibleForBonus',
  'lastActivationDt',
  'lastConfirmationDt',
  'status',
  'trustLevel'
];

/**
 * The value of the built-in `firebase_screen_class` Firebase event parameter for screen views in the app.
 * Ordinarily, this would be set automatically by FirebaseAnalytics, but due to a bug in the
 * `@capacitor-firebase/analytics` plugin, we must manually set it for iOS.
 * @see AnalyticsService.trackScreen
 * @deprecated to be cut when there is a fixed version of `@capacitor-firebase/analytics`
 */
const FIREBASE_SCREEN_CLASS_IOS = 'CAPBridgeViewController';

export enum TapElementName {
  CancelBlockCustomer = 'Rate Block customer alert cancel',
  ConfirmBlockCustomer = 'Rate Block customer alert confirm',
  CopyAddress = 'Copy address',
  CopyAddressUnsupported = 'Copy address unsupported',
  DismissCleaningInstructionsAlert = 'Dismiss cleaning instructions alert',
  DurationHelpCall = 'Help change duration call',
  DurationHelpChat = 'Help change duration chat',
  ExtraJobsAddHoursFlow = 'Add hours flow extra jobs',
  ExtraJobsEmailHousekeepFlow = 'Email Housekeep flow extra jobs',
  FinishEarlyCleaningInstructions = 'finish-early-cleaning-instructions',
  FinishEarlyFinishingInstructions = 'finish-early-finishing-instructions',
  FinishEarlyKeepCleaning = 'finish-early-keep-cleaning',
  FinishEarlyLessThanMinimumHours = 'finish-early-less-min-hours',
  FinishEarlyStartButtonImageClicked = 'finish-early-start-button-image-clicked',
  HelpAddHoursFlow = 'Add hours flow help',
  HousekeepAcademy = 'Housekeep Academy',
  JobInProgressDismiss = 'Job in progress dismiss',
  JobInProgressFinish = 'Job in progress finish',
  ModalContentCta = 'Content modal CTA',
  ModalContentTipHelpful = 'Content modal tip helpful',
  ModalContentTipNotHelpful = 'Content modal tip not helpful',
  OpenChatAfterRating = 'Open customer chat after rating',
  ReadCleaningInstructionsAlert = 'Read cleaning instructions alert',
  RescheduleBookTmeOff = 'Reschedule book time off',
  RescheduleCleanHelp = 'Reschedule this clean help',
  RescheduleSendMessage = 'Send message to customer through reschedule flow',
  ScheduleVisitHelp = 'schedule-visit-help',
  TimeOffAddHoursFlow = 'Add hours flow extra jobs',
  TimeOffBookingCard = 'Time Off booking card',
  WorkingHoursCard = 'Working Hours card'
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor(
    private authService: AuthService,
    private platform: Platform,
    private sentryService: SentryService,
    private timeService: TimeService,
    private userService: UserService
  ) {}

  public async initialize(): Promise<void> {
    await this.platform.ready();
    this.authService.subscribe(this._onAuthEvent.bind(this));
    if (await this.setUserId()) {
      await this.setInitialUserProperties();
    }
  }

  public async setUserId(): Promise<boolean> {
    if (this.platform.is('cordova')) {
      try {
        const user = await this.userService.getUser();
        await FirebaseAnalytics.setUserId({ userId: user.id });
        return true;
      } catch (err) {
        try {
          await this.clearUserId();
        } catch (err) {
          // ignore
        }
      }
    }
    return false;
  }

  public async setUserProperties(properties: Record<string, any>): Promise<void> {
    if (this.platform.is('cordova')) {
      try {
        await Promise.all(
          Object.entries(properties).map(([key, value]) =>
            FirebaseAnalytics.setUserProperty({
              key,
              value: value.toString()
            })
          )
        );
      } catch (err) {
        // ignore
      }
    }
  }

  public async clearUserId(): Promise<void> {
    if (this.platform.is('cordova')) {
      await FirebaseAnalytics.setUserId({ userId: null });
    }
  }

  /**
   * Track an event with optional parameters
   * @param eventName The name of the event
   * @param params Any additional parameters to track with the event
   */
  public async trackEvent(eventName: AnalyticsEvent, params: AnalyticsParams = {}): Promise<void> {
    if (this.platform.is('cordova')) {
      await FirebaseAnalytics.logEvent({
        name: eventName,
        params: this.truncateParams(params)
      });
    }
  }

  /**
   * Track an event indicating that the user viewed a web page in an `InAppBrowser`.
   * @param url The name of the alert
   * @param params Any additional parameters to track with the event
   */
  public trackExternalPage(url: string, params: AnalyticsParams = {}): Promise<void> {
    return this.trackEvent(AnalyticsEvent.ExternalPageView, {
      [AnalyticsParam.Data]: url,
      ...params
    });
  }

  /**
   * Track an event indicating that the user viewed an alert.
   * @param alertName The name of the alert
   * @param params Any additional parameters to track with the event
   */
  public trackAlertView(alertName: string, params: AnalyticsParams = {}): Promise<void> {
    return this.trackInteraction(AnalyticsEvent.AlertView, AnalyticsParam.AlertName, alertName, params);
  }

  /**
   * Track an event indicating that the user viewed a menu.
   * @param menuName The name of the menu
   * @param params Any additional parameters to track with the event
   */
  public trackMenuView(menuName: string, params: AnalyticsParams = {}): Promise<void> {
    return this.trackInteraction(AnalyticsEvent.MenuView, AnalyticsParam.MenuName, menuName, params);
  }

  /**
   * Track an event indicating that the user viewed a tab.
   * @param tabName The name of the tab
   * @param params Any additional parameters to track with the event
   */
  public trackTabView(tabName: string, params: AnalyticsParams = {}): Promise<void> {
    return this.trackInteraction(AnalyticsEvent.TabView, AnalyticsParam.TabName, tabName, params);
  }

  /**
   * Track an event indicating that the user clicked or tapped on something.
   * @param elementName The name of the element that was clicked/tapped
   * @param params Any additional parameters to track with the event
   */
  public trackTap(elementName: TapElementName | string, params: AnalyticsParams = {}): Promise<void> {
    return this.trackInteraction(AnalyticsEvent.Tap, AnalyticsParam.ElementName, elementName, params);
  }

  /**
   * Track a 'screen view' event indicating that the user viewed a modal.
   * @param modalName The name of the modal
   */
  public trackModal(modalName: string): Promise<void> {
    return this.trackScreen('Modal', modalName);
  }

  /**
   * Track a modal dismissal as a breadcrumb in Sentry.
   * @param modalName The name of the modal
   */
  public trackModalDismissal(modalName: string): void {
    this.sentryService.captureBreadcrumb({
      message: `Modal dismissed: ${modalName}`,
      category: HkSentryBreadcrumbCategory.UiNav
    });
  }

  /**
   * Track a 'screen view' event indicating that the user viewed a page.
   * @param pageName The name of the page
   */
  public trackPage(pageName: string): Promise<void> {
    return this.trackScreen('Page', pageName);
  }

  /**
   * Track an event indicating that the user opened a push notification.
   * @param pushData The push notification payload
   */
  public trackPushOpen(pushData: any): Promise<void> {
    const params: AnalyticsParams = {};

    // Get the title and body if possible (on iOS, these will be in aps.alert)
    let title = pushData.title;
    if (pushData.aps && pushData.aps.alert && pushData.aps.alert.title) {
      title = pushData.aps.alert.title;
    }
    let body = pushData.body;
    if (pushData.aps && pushData.aps.alert && pushData.aps.alert.body) {
      body = pushData.aps.alert.body;
    }

    if (title || body) {
      params[AnalyticsParam.Data] = [title, body].filter(s => s).join(': ');
    }

    // Push notifications originating from the backend may have a tracking ID
    if (pushData.delivery_id) {
      params[AnalyticsParam.SourceId] = pushData.delivery_id;
    }

    // Get the time at which the push notification was sent
    if (pushData['google.sent_time']) {
      params[AnalyticsParam.SourceTimestamp] = pushData['google.sent_time'];
    }

    return this.trackEvent(AnalyticsEvent.PushOpen, params);
  }

  public trackToast(message: string, params: AnalyticsParams = {}): Promise<void> {
    return this.trackInteraction(AnalyticsEvent.ToastView, AnalyticsParam.Message, message, params);
  }

  private async setInitialUserProperties(): Promise<void> {
    const worker = await this.userService.getUser();
    const today = this.timeService.today();
    const daysSinceActivation = durationBetween(toDate(worker.lastActivationDt), today).toDays();
    const daysSinceConfirmation = durationBetween(toDate(worker.lastConfirmationDt), today).toDays();

    const fcmActive = (await FirebaseMessaging.checkPermissions()).receive === 'granted';

    const properties: Partial<Serialized<Worker>> & {
      [USER_PROPERTY_DAYS_SINCE_ACTIVATION]: number;
      [USER_PROPERTY_DAYS_SINCE_CONFIRMATION]: number;
      [USER_PROPERTY_FCM_ACTIVE]: boolean;
    } = {
      [USER_PROPERTY_DAYS_SINCE_ACTIVATION]: daysSinceActivation,
      [USER_PROPERTY_DAYS_SINCE_CONFIRMATION]: daysSinceConfirmation,
      [USER_PROPERTY_FCM_ACTIVE]: fcmActive
    };

    // Get properties from the worker
    for (const prop of WORKER_PROPERTIES_TO_TRACK) {
      properties[underscoreDelimit(prop)] =
        worker[prop] instanceof moment ? (worker[prop] as DateTime).toISOString() : worker[prop];
    }

    await this.setUserProperties(properties);
  }

  private trackInteraction(
    eventName: AnalyticsEvent,
    uiProperty: string,
    uiName: string,
    params: AnalyticsParams = {}
  ): Promise<void> {
    return this.trackEvent(eventName, Object.assign({ [uiProperty]: uiName }, params));
  }

  /**
   * Tracks a `screen_view` event in Firebase, with the screen name (i.e. modal or page name)
   * as the `firebase_screen` event parameter.
   */
  private async trackScreen(type: 'Modal' | 'Page', screenName: string): Promise<void> {
    if (this.platform.is('cordova')) {
      // There is a bug as of v1.3.0 of @capacitor-firebase/analytics in that both `screenName` and `screenClassOverride`
      // must be non-null, in order for a screen name (`firebase_screen`) to be passed to FirebaseAnalytics.
      const options: SetCurrentScreenOptions = { screenName };
      if (this.platform.is('ios')) {
        options.screenClassOverride = FIREBASE_SCREEN_CLASS_IOS;
      }
      await FirebaseAnalytics.setCurrentScreen(options);
    }
    this.sentryService.captureBreadcrumb({
      message: `${type} view: ${screenName}`,
      category: HkSentryBreadcrumbCategory.UiNav
    });
  }

  private truncateParams(params: AnalyticsParams): AnalyticsParams {
    const result = {};

    for (const key of Object.keys(params)) {
      result[key] = String(params[key]).substring(0, FIREBASE_MAX_PARAM_VALUE_LENGTH);
    }

    return result;
  }

  private async _onAuthEvent(event: AuthEventArgs): Promise<void> {
    const [eventName] = event;

    if (eventName === 'login') {
      await this.setUserId();
    } else if (eventName === 'logout') {
      await this.clearUserId();
    }

    await this.trackEvent(
      `auth_${eventName}` as AnalyticsEvent.AuthReady | AnalyticsEvent.Login | AnalyticsEvent.Logout
    );
  }
}
