<ion-card class="ion-margin-vertical">
  <ion-card-header class="pad-b-10">
    <ion-card-title>{{ cardTitle }}</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ng-content select="[description]"></ng-content>
    <ng-container *ngIf="!loading; else loader">
      <ng-container *ngIf="!showContactMethod(SUPPORT_CHANNEL.Email)">
        <ng-container *ngIf="isSupportUnavailable; else supportAvailable">
          <p class="mar-b-15">
            {{ supportChannelDisplay }} is currently
            <span class="text--danger">unavailable</span>
          </p>
        </ng-container>

        <ng-template #supportAvailable>
          <ng-container *ngIf="isSupportOpen; else supportOpenTimes">
            <p class="text--success strong mar-b-15">
              Open now until {{ officeHours.end | asTime }}
            </p>
          </ng-container>
        </ng-template>

        <ng-template #supportOpenTimes>
          <p>
            {{ supportChannelDisplay }} is currently
            <span class="text--danger">closed</span>
          </p>
          <p class="mar-b-15">
            Open {{ supportOpenDay }} {{ officeHours.start | asTime }}-{{
              officeHours.end | asTime
            }}
          </p>
        </ng-template>
      </ng-container>

      <ion-button
        *ngIf="showContactMethod(SUPPORT_CHANNEL.Chat)"
        expand="block"
        [disabled]="!isSupportOpen || isSupportUnavailable"
        (click)="onChat.emit()"
      >
        <hk-wc-icon slot="start" name="chat"></hk-wc-icon>
        <ng-container *ngIf="isSupportUnavailable; else chatUnavailable">
          Chat unavailable
        </ng-container>
        <ng-template #chatUnavailable>
          <ng-container *ngIf="isSupportOpen; else chatOpenTimes">Chat now</ng-container>
        </ng-template>
        <ng-template #chatOpenTimes>
          Chat closed until {{ officeHours.start | asTime }}
        </ng-template>
      </ion-button>
      <ion-button
        *ngIf="showContactMethod(SUPPORT_CHANNEL.Callback)"
        expand="block"
        [disabled]="!isSupportOpen"
        (click)="onCall.emit()"
      >
        <hk-wc-icon slot="start" name="phone"></hk-wc-icon>
        <ng-container *ngIf="isSupportOpen; else callbackOpenTimes">
          Request callback
        </ng-container>
        <ng-template #callbackOpenTimes>
          Closed until {{ officeHours.start | asTime }}
        </ng-template>
      </ion-button>
      <ng-container
        *ngIf="
          (!isSupportOpen || isSupportUnavailable) &&
          !showContactMethod(SUPPORT_CHANNEL.Email)
        "
      >
        <ion-button
          expand="block"
          fill="clear"
          (click)="showEmailOption = !showEmailOption"
        >
          Email us instead
        </ion-button>
        <hr class="mar-b-20" *ngIf="showEmailOption" />
      </ng-container>
      <ng-container *ngIf="showContactMethod(SUPPORT_CHANNEL.Email) || showEmailOption">
        <ng-content></ng-content>
      </ng-container>
    </ng-container>
    <ng-template #loader>
      <ion-spinner class="content__loading-spinner"></ion-spinner>
    </ng-template>
  </ion-card-content>
</ion-card>
