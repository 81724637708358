// CORONAVIRUS
export const FEATURE_CORONAVIRUS_CLOSED = 'coronavirus-hkapp-closed';
export const FEATURE_CORONAVIRUS_LINK_IN_HELP = 'coronavirus-hkapp-link-help';
export const FEATURE_CORONAVIRUS_LINK_IN_MENU = 'coronavirus-hkapp-link-menu';
export const FEATURE_CORONAVIRUS_PRECAUTIONS = 'coronavirus-hkapp-precautions';

// ONBOARDING
export const FEATURE_ONBOARDING_AVAILABLE_SUNDAYS = 'worker-onboarding-available-sundays';
export const FEATURE_ONBOARDING_NO_REGULAR_HOURS = 'worker-onboarding-no-regular-hours';

// TIME OFF MESSAGE
export const TIME_OFF_MESSAGE_REQUIRED = 'time-off-message-required';

// HOUSEKEEP ACADEMY
export const FEATURE_HOUSEKEEP_ACADEMY = 'worker-housekeep-academy-link';

// VISIT PAGE
export const FEATURE_VISIT_NEED_MORE_TIME = 'worker-visit-need-more-time';

// EXTRA JOBS PAGE
export const EXTRA_REGULAR_JOBS_LOCK_EXEMPTION = 'exempt-extra-regular-jobs-lock';

// Use the recommended start timeslot for schedule display
export const SHOW_RECOMMENDED_START = 'worker-recommended-start-timeslot';

// Consent worker to call recording
export const CONTACT_CONSENT_TO_CALL_RECORDING = 'contact-consent-to-call-recording';
