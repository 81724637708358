import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { addDaysToDate, OfficeHours, todayNaive, toTime } from '@housekeep/infra';

import {
  helpCategoryDefinition,
  HelpCategoryDefinition,
  SUPPORT_CHANNEL_MAP,
  SupportChannel
} from 'models/help-category';

import { AppZendeskChatService } from 'services/app-zendesk-chat-service';
import { HelpService } from 'services/help-service';
import { TimeService } from 'services/time-service';

const SUPPORT_CHANNEL_DISPLAY_MAP = {
  [SupportChannel.Chat]: 'Chat',
  [SupportChannel.Callback]: 'Phone support'
};

/**
 * Displays a preview of a chat message that can be sent to a customer.
 */
@Component({
  selector: 'contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSupport implements OnInit, OnDestroy {
  @Input() cardTitle = 'Contact Housekeep';
  @Input() helpContactCategory: string;

  @Output() onChat = new EventEmitter<void>();
  @Output() onCall = new EventEmitter<void>();

  public customerServiceIsOnline: boolean;
  public helpCategoryDefinition: HelpCategoryDefinition;
  public isSupportOpen: boolean;
  public isSupportUnavailable = false;
  public officeHours: OfficeHours;
  public loading = true;
  public showEmailOption = false;
  public supportChannelDisplay: string;
  public supportOpenDay: string;
  public supportOpenInterval: NodeJS.Timeout;

  public readonly SUPPORT_CHANNEL = SupportChannel;

  constructor(
    protected appZendeskChatService: AppZendeskChatService,
    protected helpService: HelpService,
    protected timeService: TimeService,
    private cdr: ChangeDetectorRef
  ) {}

  public async loadHelpCategoryDefinition(): Promise<void> {
    this.loading = true;
    try {
      this.helpCategoryDefinition = await this.helpService.getHelpCategoryDefinition(this.helpContactCategory);
    } catch (error) {
      this.helpCategoryDefinition = helpCategoryDefinition.create({
        emailEnabled: true,
        recommendedChannel: SupportChannel.Email
      });
    }
    this.customerServiceIsOnline = await this.appZendeskChatService.customerServiceIsOnline();

    this.officeHours = await this.helpService.getChannelOfficeHours(this.helpCategoryDefinition.recommendedChannel);

    this.loading = false;
  }

  public async ngOnInit(): Promise<void> {
    await this.loadHelpCategoryDefinition();

    this.supportChannelDisplay = SUPPORT_CHANNEL_DISPLAY_MAP.hasOwnProperty(
      this.helpCategoryDefinition?.recommendedChannel
    )
      ? SUPPORT_CHANNEL_DISPLAY_MAP[this.helpCategoryDefinition.recommendedChannel]
      : 'Support';

    const shouldSetInterval = this.setOfficeOpen(true);

    if (!this.isSupportOpen) {
      if (shouldSetInterval) {
        this.supportOpenInterval = setInterval(() => {
          this.setOfficeOpen();
        }, 30000);
      } else {
        // get tomorrow's office hours if the office is closed today
        this.officeHours = await this.helpService.getChannelOfficeHours(
          this.helpCategoryDefinition.recommendedChannel,
          addDaysToDate(todayNaive(), 1)
        );
        this.cdr.markForCheck();
      }
    }
  }

  public ngOnDestroy(): void {
    clearInterval(this.supportOpenInterval);
  }

  /**
   * Check if the support office is/will open today
   * On the first run, set if the office is open and whether it is open today or tomorrow
   * If it will open tomorrow, return false to not set the interval
   * If it is/will open today, check the current time against the office hours to set if open
   */

  public setOfficeOpen(firstRun: boolean = false): boolean {
    const now = toTime(this.timeService.now());

    this.supportOpenDay = now.unix() < this.officeHours.start.unix() ? 'today' : 'tomorrow';

    const updatedIsSupportOpen = this.officeHours.isOpen(now);
    if (this.isSupportOpen === updatedIsSupportOpen && !firstRun) {
      return;
    }
    this.isSupportOpen = updatedIsSupportOpen;
    if (this.helpCategoryDefinition?.recommendedChannel === SupportChannel.Chat) {
      this.isSupportUnavailable = this.isSupportOpen && !this.customerServiceIsOnline;
    }
    this.cdr.markForCheck();

    if (firstRun) {
      return this.supportOpenDay === 'today';
    }
  }

  public showContactMethod(channel: SupportChannel): boolean {
    return (
      this.helpCategoryDefinition.recommendedChannel === channel &&
      !!this.helpCategoryDefinition[SUPPORT_CHANNEL_MAP[this.helpCategoryDefinition.recommendedChannel]]
    );
  }
}
